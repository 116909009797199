<template>
  <!-- Version: 1.2 -->
  <h2>1. Oyun Açıklaması</h2>
  <p>
    Highlight Games IIHF Dünya Şampiyonası Oyunu, oynama fırsatları yaratmak için maçlardan rastgele seçilen buz hokeyi
    anlarının benzersiz bir yazılım tabanlı simülasyonudur.
  </p>
  <h2>2. Oyun Açıklaması ve Süresi</h2>
  <p>
    Bir elektronik bahis oyununun tek bir turu ortalama 5 dakika sürer ve söz konusu oyuna ait fikstür anonslarını,
    gösterimi ve sonuçları içerir.
  </p>
  <p>
    Öne çıkan buz hokeyi etkinliklerinin bir seçkisi istatistiksel olarak bağımsızdır, rastgeledir, tahmin edilemez ve
    Rastgele Sayı Oluşturucu - RSO tarafından üretilir.
  </p>
  <h2>3. Bahis Piyasaları</h2>
  <h3>3.1 Kesin Gol Sayısı (Uzatmalar Dahil)</h3>
  <p>
    Kesin Gol Sayısı Bahislerinin anlamı, tek bir maçta kesin o sayıda golün atılması gerektiğidir. Yani bu bahis
    çeşidi, maçın nihai sonucuna rağmen kesin sayıda golü tahmin etmeniz gerektiği anlamına gelir.
  </p>
  <h3>3.2 Ev Sahibi Takımın Kesin Gol Sayısı (Uzatmalar Dahil)</h3>
  <p>Ev sahibi takımın maçta atacağı gollerin kesin sayısına dair bahistir.</p>
  <h3>3.3 Deplasman Takımının Kesin Gol Sayısı (Uzatmalar Dahil)</h3>
  <p>Deplasman takımının maçta atacağı gollerin kesin sayısına dair bahistir.</p>
  <h3>3.4 İki Takım da Gol Atar (Normal Süre)</h3>
  <p>
    İTGA bahsini kazanmak için her iki takımın da en az bir gol atması gerekmekte olup, 1-1 ve üzeri sonuçlar ile bahis
    kazanılır. &quot;İki Takım da Gol Atar&quot; bahsi, iki takımdan yalnızca birinin gol atmayı başardığı durumlarda,
    bahisçilere karlı oranlar elde etmeleri için bu tür etkinliklerden yararlanma fırsatı tanır.
  </p>
  <h3>3.5 Doğru Skor (Uzatmalar Dahil)</h3>
  <p>
    Doğru skor bahsi, bir buz hokeyi maçının nihai sonucunun ne olacağını düşündüğünüz üzerine bahis oynamak olup, bir
    maçta en fazla 5 gol ve 18 olası sonuç olacaktır.
  </p>
  <h3>3.6 Maç Sonucu (1X2) (Normal Süre)</h3>
  <p>
    Ev sahibi takımın kazanması (1), deplasman takımının kazanması (2) veya maçın berabere bitmesi (X) üzerine oynanan
    bahistir.
  </p>
  <h3>3.7 Maç Sonucu (1,2) (Uzatmalar Dahil)</h3>
  <p>Ev sahibi takımın kazanması (1), deplasman takımının kazanması (2) üzerine oynanan bahistir.</p>
  <h3>3.8 Maç Sonucu ve 3,5 Gol Üstü/Altı (Normal Süre)</h3>
  <p>Maçın sonucuna ve toplam gol sayısının 3,5 golün altında veya üstünde olacağına dair bahistir.</p>
  <h3>3.9 Maç Sonucu ve 3,5 Gol Üstü/Altı (Uzatmalar Dahil)</h3>
  <p>Maçın sonucuna ve toplam gol sayısının 3,5 golün altında veya üstünde olacağına dair bahistir.</p>
  <h3>3.10 2,5 Ev Sahibi Takım veya Deplasman Takımı Golü Üstü/Altı (Uzatmalar Dahil)</h3>
  <p>Ev Sahibi Takımın veya Deplasman Takımının 2,5 golün altında veya üstünde gol atacağına dair bahistir.</p>
  <h3>3.11 2,5, 3,5, 4,5 Toplam Gol Sayısı Üstü (Uzatmalar Dahil)</h3>
  <p>Maçta her iki takımın attığı toplam gol sayısının 2,5, 3,5 veya 4,5&#39;in üzerinde olacağına dair bahistir.</p>
  <h3>3.12 2 Yönlü Oyun Satırları (Normal Süre)</h3>
  <p>
    Maçın favorisini veya zayıf takımını belirlemek için belirlenen oranların yer aldığı bir bahis satırıdır. Bu
    handikap, 2 takım arasında 2 olası sonucun olduğu “yarım gol” handikap satırını oluşturur.
  </p>
  <h3>3.13 3 Yönlü Oyun Satırları (Normal Süre)</h3>
  <p>
    Maçın favorisini veya zayıf takımını belirlemek için belirlenen oranların yer aldığı bir bahis satırıdır. Bu
    handikap, 2 takım arasında 3 olası sonucun olduğu (yani beraberlik dahil) “tam gol” handikap satırını oluşturur.
  </p>
  <h3>3.14 En Yüksek Skorlu Devre Satırları (Normal Süre)</h3>
  <p>Hangi devrede en çok gol atılacağına dair bahistir. Bu, 1. Devre, 2. Devre, 3. Devre veya Beraberlik olabilir.</p>
  <h3>3.15 En Çok Devreyi Kazanacak Takım (Normal Süre)</h3>
  <p>
    En çok devreyi hangi takımın kazanacağına dair oynanan bahistir. Bu Ev Sahibi Takım, Deplasman Takımı veya
    Beraberlik olabilir.
  </p>
  <h3>3.16 İlk golü hangi Takım atar? (Uzatmalar Dahil)</h3>
  <p>Maçta ilk golü hangi takımın atacağına dair bahistir.</p>
</template>
